import React, { Fragment } from 'react';
import { Trans } from 'react-i18next'
import cloneDeep from 'lodash.clonedeep';
import { Listing } from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Accordion from "../../../components/accordion/Accordion";
import ListingTab from "./ListingTab";
// import ListingPreview from "../ListingPreview";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "../../../components/form/Checkbox";
import CheckboxSetField from "../../../components/form/CheckboxSetField";
import RadioButtonsField from "../../../components/form/RadioButtonsField";
import DropdownField from "../../../components/form/DropdownField";
import {FormHelperText} from "@material-ui/core";

class AdditionalInfo extends ListingTab {

    state = {
        customCategories: []
    };

    componentDidMount() {
        const { form } = this.props;
        const customCategories = form.getField('CustomFieldsJSON') ? JSON.parse(form.getField('CustomFieldsJSON')) : [];

        this.setState({
            customCategories
        })
    }

    render() {
        const { form } = this.props;

        console.log(form);

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Social Media"
                            titleI18nKey="myListings.editView.tabSocial"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderSocialMedia()}
                        </Accordion>

                        <Accordion
                            title="Pricing"
                            titleI18nKey="myListings.editView.tabPricing"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderRates()}
                        </Accordion>

                        {form.getField('ClassNameShort') !== 'Event' && (
                            <Accordion
                                title="Google Places"
                                titleI18nKey="myListings.editView.tabGooglePlaces"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderGooglePlaces()}
                            </Accordion>
                        )}

                        {typeof form.getField('BookIt') !== 'undefined' && (
                            <Accordion
                                title="Bookit"
                                titleI18nKey="myListings.editView.tabBookit"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderBookit()}
                            </Accordion>
                        )}

                        {typeof form.getField('Qualmarked') !== 'undefined' && (
                            <Accordion
                                title="Qualmark"
                                titleI18nKey="myListings.editView.tabQualmar"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderQualmark()}
                            </Accordion>
                        )}

                        {typeof form.getField('TripAdvisorID') !== 'undefined' && (
                            <Accordion
                                title="Trip Advisor"
                                titleI18nKey="myListings.editView.tabTripAdvisor"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderTripAdvisor()}
                            </Accordion>
                        )}

                        {form.getField('ClassNameShort') === 'Accommodation' && (
                            <Accordion
                                title="Accommodation"
                                titleI18nKey="myListings.editView.tabAccommodation"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderAccommodation()}
                            </Accordion>
                        )}

                        {this.renderCustomFieldGroups()}
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderSocialMedia() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Facebook"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.facebook">Facebook</Trans>}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.facebookHelp">
                            Copy and paste your Facebook page link with https://. E.G: https://facebook.com
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Twitter"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.twitter">Twitter</Trans>}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Twitter link with https://. E.G: https://twitter.com
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Instagram"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.instagram">Instagram</Trans>}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Instagram link with https://. E.G: https://instagram.com
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Youtube"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.youtube">Youtube</Trans>}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Youtube link with https://. E.G: https://youtube.com
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="LinkedIn"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.linkedIn">LinkedIn</Trans>}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your LinkedIn link with https://. E.G: https://linkedin.com
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="SocialHashTag"
                        label={<Trans i18nKey="myListings.editView.socialHashTag">Social Hash Tag</Trans>}
                        variant="outlined"
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Enter your social hash tag starting with a #. E.G #discoverLK
                        </Trans>
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    }

    renderRates() {
        const { form } = this.props;
        const averageSpend = <Trans i18nKey="myListings.editView.spend">Average Spend</Trans>;
        const minPrice = <Trans i18nKey="myListings.editView.minPrice">Min Price</Trans>;
        const maxPrice = <Trans i18nKey="myListings.editView.maxPrice">Max Price</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="AverageSpend"
                        variant="outlined"
                        label={averageSpend}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />

                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Average spend (Enter in USD). E.G 15.00
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="MinPrice"
                        variant="outlined"
                        label={minPrice}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Minimum price (Enter in USD). E.G 15.00
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="MaxPrice"
                        variant="outlined"
                        label={maxPrice}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Maximum price (Enter in USD). E.G 15.00
                        </Trans>
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    }

    renderGooglePlaces() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="GooglePlacesID"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.googlePlacesID">Google Places ID</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="GoogleRatings"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.googleRatings">Google Ratings</Trans>}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="GooglePlacesLink"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.googlePlacesLink">Google Places Link</Trans>}
                        fullWidth
                        disabled
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Copy and paste your Google places link with https://. E.G: https://google.com
                        </Trans>
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    }

    renderBookit() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="BookIt"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.bookIt">BookIt</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="BookItOperatorID"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.bookitOperatorID">BookIt Operator ID</Trans>}
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        )
    }

    renderQualmark() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <Checkbox
                        form={form}
                        name="Qualmarked"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.Qualmarked">Qualmarked</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="QualmarkAccountID"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.qualmarkAccountID">Account ID</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="QualmarkSegment"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.qualmarkSegment">Qualmark Segment</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="QualmarkSector"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.qualmarkSector">Qualmark Sector</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="QualmarkRating"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.qualmarkRating">Qualmark Rating</Trans>}
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    renderTripAdvisor() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="TripAdvisorID"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.tripAdvisorID">TripAdvisor ID</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="TripAdvisorRating"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.tripAdvisorRating">TripAdvisor Rating</Trans>}
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        )
    }

    renderAccommodation() {
        const { form } = this.props;
        const numberOfRooms = <Trans i18nKey="myListings.editView.numOfRooms">Num Of Rooms</Trans>;
        const numberOfFunctionRooms = <Trans i18nKey="myListings.editView.numOfFunctionRooms">Num Of Function Rooms</Trans>;
        const theatreCapacity = <Trans i18nKey="myListings.editView.rheatreCapacity">Theatre Capacity</Trans>;
        const banquetCapacity = <Trans i18nKey="myListings.editView.banquetCapacity">Banquet Capacity</Trans>;
        const cocktailCapacity = <Trans i18nKey="myListings.editView.cocktailCapacity">Cocktail Capacity</Trans>;
        const boardRoomCapacity = <Trans i18nKey="myListings.editView.boardRoomCapacity">Board Room Capacity</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="NumOfRooms"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={numberOfRooms}
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="NumOfFunctionRooms"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={numberOfFunctionRooms}
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="TheatreCapacity"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={theatreCapacity}
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="BanquetCapacity"
                        variant="outlined"
                        label={banquetCapacity}
                        fullWidth
                        type="number"
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="CocktailCapacity"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={cocktailCapacity}
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="BoardRoomCapacity"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={boardRoomCapacity}
                        emptyValue={0}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Number only
                        </Trans>
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    }

    renderCustomFieldGroups() {
        const { customCategories } = this.state;

        if (!customCategories.length) return null;

        return customCategories.map(category => (
            <Accordion
                key={'custom-field-category-' + category.id}
                title={category.title}
                className='form-panel'
                defaultExpanded
            >
                <Grid container spacing={0}>
                    {this.renderCustomFields(category)}
                </Grid>
            </Accordion>
        ))
    }

    onCustomFieldChange(category, field, value, optionsIDs) {
        const { form } = this.props;
        const customCategories = cloneDeep(this.state.customCategories);

        category = cloneDeep(category);
        const categoryIndex = customCategories.findIndex(cat => cat.id === category.id)
        if (categoryIndex >= 0) {
            const fieldIndex = category.fields.findIndex(f => f.id === field.id);
            field = cloneDeep(field);
            if (fieldIndex >= 0) {
                field.value = value;
                field.optionIDs = optionsIDs;
                field.changed = true;

                category.fields[fieldIndex] = field;

                customCategories[categoryIndex] = category;

                this.setState({ customCategories })

                const CustomFieldsJSON = JSON.stringify(customCategories);
                form.setField({
                    CustomFieldsJSON
                })
            }
        }
    }

    renderCustomFields(category) {
        const fields = category.fields;

        return fields.map((field, index) => {
            let fieldDom = null;
            switch (field.type) {
                case 'Text':
                    fieldDom = (
                        <TextField
                            label={field.title}
                            variant="outlined"
                            fullWidth
                            value={field.value}
                            onChange={(e) => this.onCustomFieldChange(category, field, e.target.value)}
                        />
                    );
                    break;
                case 'Dropdown':
                    fieldDom = (
                        <DropdownField
                            label={field.title}
                            fullWidth
                            options={field.options.map(option => {
                                return {
                                    label: option.title,
                                    value: option.title,
                                }
                            })}
                            value={field.value}
                            onChange={(e) => {
                                const option = field.options.find(o => o.title === e.target.value);
                                if (option) {
                                    this.onCustomFieldChange(category, field, e.target.value, option.id)
                                }
                            }}
                        />
                    );
                    break;
                case 'Checkbox':
                    fieldDom = (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!field.value}
                                    color="primary"
                                    onChange={(e) => this.onCustomFieldChange(category, field, e.target.checked)}
                                />
                            }
                            label={field.title}
                        />
                    );
                    break;
                case 'Checkboxset':
                    fieldDom = (
                        <CheckboxSetField
                            fieldLabel={field.title}
                            onChange={options => {
                                const checked = options.filter(o => o.checked);
                                const value = checked.map(o => o.label).join(',')
                                const optionIDs = checked.map(o => o.id).join(',')
                                this.onCustomFieldChange(category, field, value, optionIDs)
                            }}
                            options={field.options.map(option => {
                                return {
                                    label: option.title,
                                    checked: field.optionIDs.split(',').map(id => parseInt(id,10)).includes(option.id),
                                    id: option.id
                                }
                            })}
                        />
                    );
                    break;
                case 'RadioButtons':
                    fieldDom = (
                        <RadioButtonsField
                            label={field.title}
                            value={field.value}
                            options={field.options.map(option => {
                                return {
                                    label: option.title,
                                    value: option.title,
                                }
                            })}
                            onChange={(e) => {
                                const option = field.options.find(o => o.title === e.target.value);
                                if (option) {
                                    this.onCustomFieldChange(category, field, e.target.value, option.id)
                                }
                            }}
                        />
                    );
                    break;
                default:
                    fieldDom = null;
                    break;
            }
            if (fieldDom) {
                return (
                    <Grid item className="field-holder">
                        {fieldDom}

                        {!!field.description && (
                            <FormHelperText>
                                {field.description}
                            </FormHelperText>
                        )}
                    </Grid>
                );
            }
        })
    }
}

export default (isEvent = false) => ({
    id: 'integrations',
    tabURL: `/${isEvent ? 'event' : 'listing'}/edit/:id/other`,
    label: 'Other',
    component: AdditionalInfo,
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

    }
});