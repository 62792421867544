import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import { Icon, Button, Typography } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next'
import Grid from '../../components/layout/Grid';
import DataTable from '../../components/dataTable/DataTable'
import {isFunction, joinDefined} from "../../utils/strings";
import {getUser} from "../../utils/sessions";
import ViewHeader from '../../components/header/ViewHeader';
import Spinner from '../../components/loading/Spinner';
import {hasListingsClaimsQuery, listingColumns, listingFragment} from "../Listings/Listings";
import Main from "../Main";
import Footer from "../../components/footer/Footer";
import {siteConfigQuery} from "../../App";

class Dashboard extends Component {

    render() {

        const { config } = this.props;

        return (
            <Fragment>

                {!!config.HomePageContent && (
                    <div className={'section'}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography variant="body1">
                                    {!!config.HomePageContent && (
                                        <div dangerouslySetInnerHTML={{__html: config.HomePageContent}} />
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                )}

                {this.renderListings()}

                {this.renderClaimedRequests()}

            </Fragment>
        );
    }

    renderListings() {
        const { history, config } = this.props;
        return (
            <div className={'section'}>
                <Grid container spacing={3}>
                    <Grid item>
                        <ViewHeader
                            variant="record"
                            titleTag="h3"
                            title={<Trans i18nKey="myListings.listView.listingsTitle">Your Current Listings</Trans>}
                        >
                            <Button variant="outlined" onClick={e => history.push('/listings/new')}>
                                <Trans i18nKey="myListings.listView.addTitle">
                                    Add a new listing
                                </Trans>
                            </Button>
                        </ViewHeader>

                        <Grid contianer className='table-container'>
                            <Grid item className="listings">
                                <DataTable
                                    className="table-listings"
                                    queryName="readListings"
                                    fragment={listingFragment}
                                    columns={listingColumns}
                                    variables={{ limit: 2, memberID: getUser().ID }}
                                    brandProperty="ID"
                                    searchVariable="contains"
                                    tableTitle="Listings"
                                    versioningMode="DRAFT"
                                    hidePagination
                                    suffixButtonLink="/listings"
                                    suffixButtonText="See all listings"
                                    noResultsMessage={<Trans i18nKey="myListings.noResultsMessage">You have no listings added yet.</Trans>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderClaimedRequests() {
        const { history, config = {} } = this.props;
        return (
            <Query
                fetchPolicy="network-only"
                query={hasListingsClaimsQuery}
                variables={{ memberID: getUser().ID}}
            >
                {results => {
                    const { loading, data, refetch } = results;

                    if (loading) return null;

                    const requests = data.readMemberHasListings;

                    return (<Grid container spacing={3}>
                        <Grid item>

                            <ViewHeader
                                variant="record"
                                titleTag="h3"
                                title={<Trans i18nKey="myListings.claimListing.addTitle">Claim an existing listing</Trans>}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={e => history.push('/listings/claim')}
                                >
                                    <Trans i18nKey="myListings.claimListing.claim">
                                        Claim a listing
                                    </Trans>
                                </Button>
                            </ViewHeader>

                            <Typography variant="body1">
                                <Trans i18nKey="myListings.claimListing.addContent1">
                                    If your business is already listed on
                                </Trans>&nbsp;

                                {!!config.SiteLink && !!config.SiteTitle ? (
                                    <strong><a href={config.SiteLink} target="_blank">{config.SiteTitle}</a></strong>
                                ) : 'our site'}, &nbsp;
                                <Trans i18nKey="myListings.claimListing.addContent2">
                                    you can claim a listing by clicking the button below.
                                </Trans>
                            </Typography>
                        </Grid>

                    </Grid>)
                }}
            </Query>
        );
    }

}

export default withTranslation()(Dashboard);
