import gql from "graphql-tag";

export const ListingFields = (includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields) => (`
	ID
	ClassNameShort
	URLSegment
	Status
	Title
	Email
	${LocationFields}
	Website
	Summary
	Content
	Facilities
	BestTimeToTravel
	AgeRestrictions
	Featured
	Phone
	MobilePhone
	FreePhone
	BookingLink
	VideoLink
	OpeningHours
	OperatingMonths
	IsTwentyFourSeven
	Facebook
	Twitter
	Instagram
	Youtube
	LinkedIn
	SocialHashTag
	AverageSpend
	MinPrice
	MaxPrice
	GooglePlacesID
	GoogleRatings
	GooglePlacesLink
	ProximityToTown
	ProximityToAirport
	ProximityToRailway
	ProximityToBus
	SpecialAlert
	SpecialAlertFromDate
	SpecialAlertToDate
	DealTitle
	DealSecondaryTitle
	Deal
	DealURL
	DealImage {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	DealFromDate
	DealToDate
	Version
	VersionForReview
	${includeTripAdvisor ? TripAdvisorFields : ''}
	${!!includeQualmark ? QualmarkFields : ''}
	${!!includeBookit ? BookitFields : ''}
	${includeCustomFields ? 'CustomFieldsJSON' : ''}
	Logo {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	MainImage {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	MainCategory {
		ID
		Title
	}
	Categories {
		ID
		Title
	}
	Tags {
		ID
		Title
	}
	GalleryImages {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	VideoLink
`);

export const LocationFields = `
	StreetAddress
	City
	Suburb
	State
	PostCode
	Country
	PhysicalAddressAsPostal
	PostalStreetAddress
	PostalCity
	PostalSuburb
	PostalState
	PostalPostCode
	PostalCountry
	Location
`;

export const TripAdvisorFields = `
	TripAdvisorID
	TripAdvisorRating
	TripAdvisorReviews
	TripAdvisorLink
	TripAdvisorRatingImageURL
	TripAdvisorLastUpdated
`;

export const QualmarkFields = `
	Qualmarked
	QualmarkAccountID
	QualmarkSegment
	QualmarkSector
	QualmarkRating
`;

export const BookitFields = `
	BookIt
	BookItOperatorID
`;

export const Listing = (includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields) => {
	return gql`
		fragment ListingFragmentListing on Listing {
			${ListingFields(includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields)}
		}
	`;
}
