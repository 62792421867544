import React, { Component, Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import gql from "graphql-tag";
import { Icon, Button, Typography } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next'
import Grid from '../../components/layout/Grid';
import DataTable from '../../components/dataTable/DataTable'
import {getUser} from "../../utils/sessions";
import ViewHeader from '../../components/header/ViewHeader';
import {listingColumns, listingSummaryFields} from "../Listings/Listings";
import {niceDateTimeFromString} from "../../utils/date";
import CreateListingModal from "../Listings/Modals/CreateListingModal";

class Events extends Component {

    state = {
        showCreateModal: false,
    };

    refetchClaims = null;

    config = null;

    static getDerivedStateFromProps({ match }, oldState) {
        if (match.params.type && match.params.type === 'new') {
            return {
                showCreateModal: true,
            }
        }
        return null;
    }

    render() {
        const { showCreateModal } = this.state;
        const { history, config } = this.props;

        return (
            <Fragment>

                <div className='section'>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant="h1">
                                <Trans i18nKey="myEvents.listView.title">
                                    List your event on
                                </Trans>
                                {!!config ? ` ${config.SiteTitle}` : ' our site'}
                            </Typography>
                            <Typography variant="body1">
                                <Trans i18nKey="myEvents.listView.descPart1">
                                    Get your events listed on
                                </Trans>&nbsp;
                                {!!config ? config.SiteTitle : 'our site '}
                                &nbsp;<Trans i18nKey="myEvents.listView.descPart2">
                                to be found online and generate more bookings. Learn more about
                            </Trans>&nbsp;
                                {!!config && (
                                    <a href={config.SiteLink} target="_blank">{config.SiteTitle}</a>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

                {this.renderEvents()}

                <CreateListingModal
                    isEvent
                    onClose={() => this.onCloseCreateModal()}
                    open={showCreateModal}
                    history={history}
                />
            </Fragment>
        );
    }

    renderEvents() {
        const { history, config } = this.props;

        return (
            <Fragment>
                <div className={'section'}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <ViewHeader
                                variant="record"
                                titleTag="h2"
                                title={<Trans i18nKey="myEvents.listView.listingsTitle">Your Events</Trans>}
                            >
                                <Button variant="outlined" onClick={e => history.push('/events/new')}>
                                    <Trans i18nKey="myEvents.listView.addTitle">
                                        Add a new event
                                    </Trans>
                                </Button>
                            </ViewHeader>

                            <Grid contianer className='table-container'>
                                <Grid item className="listings">
                                    <DataTable
                                        className="table-listings"
                                        queryName="readEvents"
                                        fragment={eventFragment}
                                        columns={eventColumns()}
                                        variables={{ limit: 10, memberID: getUser().ID, expired: false }}
                                        brandProperty="ID"
                                        searchVariable="contains"
                                        tableTitle="Events"
                                        versioningMode="DRAFT"
                                        noResultsMessage={<Trans i18nKey="myEvents.noResultsMessage">You have no events added yet.</Trans>}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={'section'}>
                    <Grid container spacing={3}>

                        <Grid item>
                            <ViewHeader
                                variant="record"
                                titleTag="h2"
                                title={<Trans i18nKey="myEvents.listView.listingsTitle">Past Events</Trans>}
                            />

                            <Grid contianer className='table-container'>
                                <Grid item className="listings">
                                    <DataTable
                                        queryName="readEvents"
                                        fragment={eventFragment}
                                        columns={eventColumns()}
                                        variables={{ limit: 10, memberID: getUser().ID, expired: true }}
                                        brandProperty="ID"
                                        searchVariable="contains"
                                        tableTitle="Events"
                                        versioningMode="DRAFT"
                                        noResultsMessage={<Trans i18nKey="myEvents.noResultsMessage">You have no expired events yet.</Trans>}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        );
    }

    onCloseCreateModal() {
        const { history } = this.props;
        this.setState({ showCreateModal: false });
        history.push('/events');
    }
}

const startDateColumn = {
    label: 'Start Date',
    key: 'date',
    hiddenSm: true,
    renderCell: row => {
        console.log('row', row)
        return (
            <span>{niceDateTimeFromString(row.StartDate)}</span>
        );
    }
};
const endDateColumn = {
    label: 'End Date',
    key: 'date',
    hiddenSm: true,
    renderCell: row => {
        return (
            <span>{niceDateTimeFromString(row.EndDate)}</span>
        );
    }
};
export const eventColumns = () => {
    const columns = cloneDeep(listingColumns)
    if (!columns.filter(col => col.key === 'date').length) {
        columns.splice(2, 0, startDateColumn, endDateColumn)
    }
    return columns;
}

export const eventFragment = gql`
    fragment EventFragment on Event {
        ${listingSummaryFields}
        StartDate
        EndDate
        
        Performances {
            ID
            StartDate
            EndDate
            Prices
        }
    }
`;


export default withTranslation()(Events);
