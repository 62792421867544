import React, { Fragment } from 'react';
import { Trans } from 'react-i18next'
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import { Listing } from "../../../fragments/Listing";
import ListingTab from "./ListingTab";
import Accordion from "../../../components/accordion/Accordion";
import FormLabel from "@material-ui/core/FormLabel";
import UploadField from "../../../components/form/UploadField";
import {FormHelperText} from "@material-ui/core";

class DealsAndAlerts extends ListingTab {

    render() {
        const { form } = this.props;
        const objectType = form.getField('ClassNameShort');

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Special Alerts"
                            titleI18nKey="myListings.editView.tebAlerts"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderSpecialAlerts()}
                        </Accordion>

                        {objectType !== 'Event' && (
                            <Accordion
                                title="Deals"
                                titleI18nKey="myListings.editView.tabDeals"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderDeals()}
                            </Accordion>
                        )}
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderSpecialAlerts() {
        const { form } = this.props;
        const specialAlert = <Trans i18nKey="myListings.editView.specialAlert">Special Alert</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="SpecialAlert"
                        variant="outlined"
                        label={specialAlert}
                        fullWidth
                        multiline
                    />
                </Grid>

                <Grid item className="field-holder">
                    <FormLabel>From Date</FormLabel>
                    <TextField
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        form={form}
                        name={`SpecialAlertFromDate`}
                    />
                </Grid>

                <Grid item className="field-holder">
                    <FormLabel>To Date</FormLabel>
                    <TextField
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        form={form}
                        name={`SpecialAlertToDate`}
                    />
                </Grid>
            </Grid>
        )
    }

    renderDeals() {
        const { form } = this.props;
        const dealImage = form.getField('DealImage');

        const dealTitle = <Trans i18nKey="myListings.editView.dealTitle">Main special offer title</Trans>;
        const dealSecondaryTitle = <Trans i18nKey="myListings.editView.dealSecondaryTitle">Special offer title</Trans>;
        const deal = <Trans i18nKey="myListings.editView.deal">Special offer description</Trans>;
        const dealURL = <Trans i18nKey="myListings.editView.dealURL">Booking URL</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="DealTitle"
                        variant="outlined"
                        label={dealTitle}
                        fullWidth
                    />
                </Grid>

                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="DealSecondaryTitle"
                        variant="outlined"
                        label={dealSecondaryTitle}
                        fullWidth
                    />
                </Grid>

                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Deal"
                        variant="outlined"
                        label={deal}
                        fullWidth
                        multiline
                        rows={5}
                    />
                </Grid>

                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="DealURL"
                        variant="outlined"
                        label={dealURL}
                        fullWidth
                    />
                </Grid>

                <Grid item className="field-holder">
                    <h4><Trans i18nKey="myListings.editView.dealImage">Special offer image</Trans></h4>
                    <UploadField
                        folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                        name="DealImage"
                        form={form}
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.mainImageHelp">
                            Upload in JPG, PNG format. Maximum file size allowed is 2MBs
                        </Trans>
                    </FormHelperText>

                    {!!dealImage && !!dealImage.ID && this.renderImage(dealImage, this.onDealImageRemove.bind(this))}
                </Grid>

                <Grid item className="field-holder">
                    <FormLabel>From Date</FormLabel>
                    <TextField
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        form={form}
                        name={`DealFromDate`}
                    />
                </Grid>

                <Grid item className="field-holder">
                    <FormLabel>To Date</FormLabel>
                    <TextField
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        form={form}
                        name={`DealToDate`}
                    />
                </Grid>
            </Grid>
        )
    }

    onDealImageRemove() {
        const { form } = this.props;
        form.setField({
            DealImage: {
                ID: 0,
                AbsoluteLink: null,
                Name: null,
            },
            DealImageID: 0,
        })
    }

}

export default (isEvent = false) => ({
    id: 'dealsAndAlerts',
    tabURL: `/${isEvent ? 'event' : 'listing'}/edit/:id/deals-and-alerts`,
    label: isEvent ? 'Alerts' : 'Deals & Alerts',
    component: DealsAndAlerts,
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        // handle deal image save
        const dealImage = saveData.DealImage;
        if (dealImage && dealImage.ID) {
            saveData.DealImageID = dealImage.ID;
        }
        delete saveData.DealImage;
    }
});