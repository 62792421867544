import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import Route from '../components/Route';
import NotFound from './NotFound';
import Dashboard from './Dashboard/Dashboard';
import Login from "./SignUp/Login";
import Register from "./SignUp/Register";
import ResetPassword from "./SignUp/ResetPassword";
import Listings from "./Listings/Listings";
import Account from "./Account/Account";
import ListingEdit from "./Listings/ListingEdit";
import Events from "./Events/Events";
import EventEdit from "./Events/EventEdit";

const Main = ({ signedIn, config }) => (
    <main className={signedIn ? 'app-container' : ''}>
        <Switch>
            <Route auth path="/" exact component={(props) => <Dashboard config={config} {...props} />} />
            <Route auth path="/listings" exact component={(props) => <Listings config={config} {...props} />} />
            <Route auth path="/listings/:type" exact component={(props) => <Listings config={config} {...props} />} />
            <Route auth path="/listing/edit/:id/" exact component={(props) => <ListingEdit config={config} {...props} />} />
            <Route auth path="/listing/edit/:id/:tab" exact component={(props) => <ListingEdit config={config} {...props} />} />

            <Route auth path="/events" exact component={(props) => <Events config={config} {...props} />} />
            <Route auth path="/events/:type" exact component={(props) => <Events config={config} {...props} />} />
            <Route auth path="/event/edit/:id/" exact component={(props) => <EventEdit config={config} {...props} />} />
            <Route auth path="/event/edit/:id/:tab" exact component={(props) => <EventEdit config={config} {...props} />} />

            <Route auth path="/account" exact component={Account} />
            <Route anon path="/login" exact component={(props) => <Login config={config} {...props} />} />

            <Route anon path="/register/" exact component={(props) => <Register config={config} {...props} />} />
            <Route anon path="/register/:type" exact component={(props) => <Register config={config} {...props} />} />
            <Route anon path="/reset-password" exact component={(props) => <ResetPassword config={config} {...props} />} />
            <Route component={NotFound} />
        </Switch>
    </main>
);

export default withStyles({})(Main);
