import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withTranslation, Trans } from 'react-i18next'
import { Icon, Button } from '@material-ui/core';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import BasicDetails from './Tabs/BasicDetails';
import ContactDetails from './Tabs/ContactDetails';
import BusinessInfo from './Tabs/BusinessInfo';
import Media from './Tabs/Media';
import AdditionalInfo from './Tabs/AdditionalInfo';
import Spinner from '../../components/loading/Spinner';
import ViewHeader from '../../components/header/ViewHeader';
import { Accommodation } from "../../fragments/Accommodation";
import { Listing } from "../../fragments/Listing";
import Preview from "./Tabs/Preview";
import DealsAndAlerts from "./Tabs/DealsAndAlerts";

/**
 * Tab Definition
 */


class ListingEdit extends Component {

    constructor(props) {
        super(props);

        const config = props.config;
        const ListingTabs = [
            BasicDetails(),
            ContactDetails,
            BusinessInfo,
            Media(),
            DealsAndAlerts(),
            AdditionalInfo(),
        ];
        if (config.ListingPreviewURLPattern) {
            ListingTabs.push(Preview());
        }

        this.tabs = ListingTabs;
    }

    render() {
        const { match, config } = this.props;
        const includeTripAd = config.TripAdvisorExists === '1';
        const includeQualmark = config.QualmarkExits === '1';
        const includeBookit = config.BookitExists === '1';
        const includeCustomFields = config.CustomFieldsExists === '1';
        return (
            <Fragment>
                <Query query={query} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading) return <Spinner/>;

                        const listing = data && data.readOneListing;
                        if (!listing) return null;

                        return (
                            <Fragment>
                                {this.renderHeader(data && data.readOneListing)}

                                <Paper elevation={0} className='step-forms-container'>
                                    <DataFormView
                                        tabs={this.tabs}
                                        createNewFunc={{
                                            Title: null,
                                        }}
                                        objectType={listing.ClassNameShort === 'Accommodation' ? 'Accommodation' : 'Listing'}
                                        customFragment={listing.ClassNameShort === 'Accommodation'
                                            ? Accommodation(includeTripAd, includeQualmark, includeBookit, includeCustomFields)
                                            : Listing(includeTripAd, includeQualmark, includeBookit, includeCustomFields)}
                                        itemId={match.params.id}
                                        name="Listing"
                                        onCreateNew={e => this.onCreateNew(e)}
                                        context={this}
                                        versioningMode="DRAFT"
                                        mutationName={listing.ClassNameShort === 'Accommodation' ? 'updatePortalAccommodation' : 'updatePortalListing'}
                                        mutationInputType="ListingInput"
                                        extraFields={['Version', 'VersionForReview']}
                                        config={config}
                                    />
                                </Paper>
                            </Fragment>
                        );

                    }}
                </Query>
            </Fragment>
        );
    }

    renderHeader = (listing) => {
        if (!listing) return null;

        const { history } = this.props;
        return (
            <Grid item>
                <ViewHeader variant="record" title={listing.MainCategory.Title} subtitle={listing.Title}>
                    <Button onClick={() => history.push('/listings')} variant="outlined">
                        <Icon>chevron_left</Icon>
                        <Trans i18nKey="backButton">
                            Back
                        </Trans>
                    </Button>
                </ViewHeader>
            </Grid>
        );
    };

    onCreateNew(event) {
        const { history } = this.props;
        history.push(`event/edit/${event.ID}`);
    }
}

const query = gql`
    query readOneListing($id: ID!) {
        readOneListing(ID: $id, Versioning: { Mode: DRAFT}) {
            ID
            Title
            ClassNameShort
            MainCategory {
                Title
            }
        }
    }
`;

export default withTranslation()(ListingEdit);
