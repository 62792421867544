import React, { Fragment } from 'react';
import {Query} from "react-apollo";
import { Trans } from 'react-i18next'
import Spinner from '../../../components/loading/Spinner';
import Grid from "../../../components/layout/Grid";
import { Listing } from "../../../fragments/Listing";
import ListingTab from "../../Listings/Tabs/ListingTab";
import gql from "graphql-tag";
import Table, {Cell, HeaderRow, Row} from "../../../components/table/Table";
import {Button, IconButton} from "@material-ui/core";
import EditIcon from "../../../components/icon/EditIcon";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import {niceDateTimeFromString} from "../../../utils/date";
import ViewHeader from "../../../components/header/ViewHeader";
import Performance from "../../../fragments/Performance";
import PerformanceModal from "../Modal/PerformanceModal";
import DataFormView from "../../../components/DataFormView";
import Modal from "../../../components/modal/Modal";
import AlertModal from "../../../components/modal/AlertModal";
import {getClient} from "../../../utils/apollo";
import LocationDetailModal from "../Modal/LocationDetailModal";

class Performances extends ListingTab {

    state = {
        deletePrompt: null,
        createModal: null,
        currentPerformanceID: null
    };

    refetchPerformances = null;

    render() {
        const { form } = this.props;
        const { currentPerformanceID, createModal, deletePrompt } = this.state;
        const eventID = form.getField('ID');

        const closeModal = refresh => {
            this.setState(
                { createModal: null, currentPerformanceID: null },
                () => !!refresh && !!this.refetchPerformances && this.refetchPerformances()
            );
        };

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Grid container spacing={0}>
                            <Grid item>
                                <ViewHeader
                                    variant="record"
                                    titleTag="h2"
                                    title={<Trans i18nKey="myEvents.detailView.detailTitle">Your current dates</Trans>}
                                >
                                    <Button variant="outlined" onClick={e => {this.setState({ createModal: true })}}>
                                        <Trans i18nKey="myEvents.detailView.addPerformance">
                                            Add a date
                                        </Trans>
                                    </Button>
                                </ViewHeader>
                                <Query variables={{ id: eventID }} query={performancesQuery}>
                                    {({ data, loading, error, refetch }) => {
                                        if (!!loading)
                                            return (
                                                <span>
                                                    <Spinner /> Loading...
                                                </span>
                                            );
                                        if (!!error)
                                            return (
                                                <span>
                                                    Error! Can't load associated orders.
                                                </span>
                                            );
                                        this.refetchPerformances = refetch;
                                        const performances = (!!data && !!data.readOneEvent && data.readOneEvent.Performances) || [];

                                        return (
                                            performances.length > 0 && (
                                                <Table>
                                                    <HeaderRow pad>
                                                        <Cell colSpan={1}>Start Date</Cell>
                                                        <Cell colSpan={1}>End Date</Cell>
                                                        <Cell colSpan={1}>Venue</Cell>
                                                        <Cell colSpan={1}>Prices</Cell>
                                                        <Cell colSpan={1}>Actions</Cell>
                                                    </HeaderRow>

                                                    {performances.map(performance =>
                                                        <Row pad key={performance.ID}>
                                                            <Cell dataLabel="Start Date">{niceDateTimeFromString(performance.StartDate)}</Cell>
                                                            <Cell dataLabel="End Date">{niceDateTimeFromString(performance.EndDate)}</Cell>
                                                            <Cell dataLabel="Venue">{performance.Venue}</Cell>
                                                            <Cell dataLabel="Prices">{performance.Prices}</Cell>
                                                            <Cell dataLabel="Actions">
                                                                <IconButton
                                                                    className="icon"
                                                                    title="Edit"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            createModal: true,
                                                                            currentPerformanceID: performance.ID
                                                                        })
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    className="icon"
                                                                    title="Delete"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            deletePrompt: performance.ID
                                                                        })
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Cell>
                                                        </Row>
                                                    )}
                                                </Table>
                                            ) || <span>There are currently no dates.</span>
                                        );
                                    }}
                                </Query>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {this.renderButtons()}

                {!!createModal && (
                    <Modal
                        onClose={closeModal}
                        open
                        title={`${eventID ? 'Edit' : 'Create'} Performance`}
                    >
                        <DataFormView
                            tabs={[
                                {
                                    id: 'Performance',
                                    label: 'Basic Details',
                                    component: PerformanceModal,
                                    fragment: Performance,
                                    onLoad: data => {},
                                    formatSaveData: (saveData, state) => {
                                        saveData.EventID = eventID;
                                    }
                                },
                                {
                                    id: 'PerformanceLocation',
                                    label: 'Location',
                                    component: LocationDetailModal,
                                    fragment: Performance,
                                    onLoad: data => {},
                                    formatSaveData: (saveData, state) => {
                                        const location = state.Location;
                                        if (location && typeof location === 'object' && location.lat && location.lng) {
                                            saveData.Location = `POINT(${location.lng} ${location.lat})`;
                                        }
                                    }
                                }
                            ]}
                            createNewFunc={() => ({
                                StartDate: null
                            })}
                            objectType="Performance"
                            itemId={currentPerformanceID}
                            name="Performance"
                            context={this}
                            onCreateNew={() => !!this.refetchPerformances && this.refetchPerformances()}
                            additionalActions={[
                                {
                                    label: 'Close',
                                    variant: 'secondary',
                                    onClick: closeModal,
                                    skipSave: true
                                }
                            ]}
                        />
                    </Modal>
                )}

                {!!deletePrompt && (
                    <AlertModal
                        open={!!deletePrompt}
                        primaryAction="Yes"
                        onClickPrimaryAction={this.deletePerformance}
                        secondaryAction="No"
                        onClickSecondaryAction={() => this.setState({ deletePrompt: null })}
                        onClose={() => this.setState({ deletePrompt: null })}
                        variant="warning"
                    >
                        Are you sure you want to delete this association?
                    </AlertModal>
                )}
            </Fragment>
        );
    }

    deletePerformance = async () => {
        const that = this;
        const { deletePrompt } = this.state;

        await getClient()
            .mutate({ mutation: deletePerformanceMutation, variables: { IDs: [deletePrompt] } })
            .then(() => {
                that.setState(
                    { deletePrompt: null },
                    () => !!that.refetchPerformances && that.refetchPerformances()
                );
            })
            .catch(error => {
                console.error(error);
                that.setState({ deletePrompt: null });
            });
    };

}

const deletePerformanceMutation = gql`
    mutation DeletePerformance($IDs: [ID]!) {
        deletePerformance(IDs: $IDs) {
            ID
        }
    }
`;

const performancesQuery = gql`
    ${Performance}
    query ReadEventPerformances($id: ID) {
        readOneEvent(ID: $id, Versioning: { Mode: DRAFT }) {
            ID
            Performances {
                ...Performance
            }
        }
    }
`;

export default {
    id: 'performances',
    tabURL: '/event/edit/:id/performances',
    label: 'Dates',
    component: Performances,
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {}
}